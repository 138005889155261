






















import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    img: {
      type: String,
      required: true,
      default: require("@/assets/banner-subinfo.jpg")
    },
    title: {
      type: String,
      required: true
    }
  }
})
export default class SubinfoBanner extends Vue {
  get height() {
    switch (this.$vuetify.breakpoint.name) {
      case "sm":
        return 300;
      case "md":
        return 600;
      case "lg":
        return 600;
      case "xl":
        return 600;
      default:
        return 300;
    }
  }

  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    }
  ];
}
