<template>
  <div>
    <div class="page-section-alt">
      <banner :title="meta.title" />

      <v-container>
        <v-row>
          <v-col cols="12" md="5" offset-md="1" class="pb-12 mb-12">
            <v-list style="background-color: transparent;">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-avatar>
                  <v-icon class="secondary" dark>mdi-file-pdf</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon :href="item.link">
                    <v-icon color="secondary">mdi-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";

export default {
  components: {
    Banner
  },

  data() {
    return {
      meta: {
        title: "KAHEV Faaliyet Raporları"
      },
      items: [
        {
          title: "2023 Yılı Faaliyet Raporu",
          link: "/files/kahev-2023-faaliyet-raporu.pdf"
        },
        {
          title: "2023 Yılı Dış Denetim Raporu",
          link: "/files/kahev-2023-dis-denetim-raporu.pdf"
        },
        {
          title: "2022 Yılı Bağımsız Denetim Raporu",
          link: "/files/kahev-2022-bagimsiz-denetim-raporu.pdf"
        },
        {
          title: "2022 Yılı Dış Denetim Raporu",
          link: "/files/kahev-2022-dis-denetim-raporu.pdf"
        },
        {
          title: "2022 Yılı Faaliyet Raporu",
          link: "/files/kahev-2022-faaliyet-raporu.pdf"
        },
        {
          title: "2021 Yılı Dış Denetim Raporu",
          link: "/files/kahev-2021-dis-denetim-raporu.pdf"
        },
        {
          title: "2021 Yılı Faaliyet Raporu",
          link: "/files/kahev-2021-faaliyet-raporu.pdf"
        },
        {
          title: "2020 Yılı Dış Denetim Raporu",
          link: "/files/kahev-2020-dis-denetim-raporu.pdf"
        },
        {
          title: "2020 Yılı Faaliyet Raporu",
          link: "/files/kahev-2020-faaliyet-raporu.pdf"
        },
        {
          title: "2019 Yılı Faaliyet Raporu",
          link: "/files/kahev-2019-faaliyet-raporu.pdf"
        },
        {
          title: "2019 Yılı Beyannamesi",
          link: "/files/kahev-2019-beyannamesi.pdf"
        }
      ]
    };
  },

  metaInfo() {
    const title = this.meta.title;

    return {
      title: title
    };
  }
};
</script>

<style lang="scss" scoped></style>
